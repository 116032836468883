const postalCodePatternUk = '^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$' // AB12 3CD; A1B 2CD; AB1 2CD; AB1C 2DF; A12 3BC; A1 2BC
const postalCodePatternUs1 = '^[0-9]{5}\\-[0-9]{4}$' // 12345-6789

const postalCodePatternNumbers2DashNumbers3 = '^[0-9]{2}\\-[0-9]{3}$' // 12-345
const postalcodePatternNumbers3SpaceNumbers2 = '^[0-9]{3}? ?[0-9]{2}$' // 123 45
const postalCodePatternNumbers5 = '^[0-9]{5}$' // 12345
const postalCodePatternNumbers4 = '^[0-9]{4}$' // 1234
const postalCodePatternDefault = '^[a-z0-9][a-z0-9- ]{0,10}[a-z0-9]$'

export function getPostalCodePattern(countryCode: string) {
  switch (countryCode) {
    case 'NL':
      return {
        pattern: new RegExp('^[0-9]{4}\\s?[a-zA-Z]{2}$'),
        example: '1234AB',
      }
    case 'GB':
      return {
        pattern: new RegExp(postalCodePatternUk),
        example: 'AB12 3CD; A1B 2CD; AB1 2CD; AB1C 2DF; A12 3BC; A1 2BC',
      }
    case 'IE':
      return {
        pattern: new RegExp(
          '^([AC-FHKNPRTV-Y]\\d{2}|D6W)\\s[0-9AC-FHKNPRTV-Y]{4}$',
        ),
        example: 'A12 CD34',
      }
    case 'DE':
    case 'FR':
    case 'ES':
    case 'IT':
      return {
        pattern: new RegExp(postalCodePatternNumbers5),
        example: '12345',
      }
    case 'AT':
    case 'BE':
    case 'DK':
    case 'NO':
    case 'CH':
      return {
        pattern: new RegExp(postalCodePatternNumbers4),
        example: '1234',
      }
    case 'SE':
      return { pattern: new RegExp('^[0-9]{3}\\s[0-9]{2}$'), example: '123 45' }
    case 'PL':
      return {
        pattern: new RegExp(postalCodePatternNumbers2DashNumbers3),
        example: '12-345',
      }
    case 'CZ':
      return {
        pattern: new RegExp(postalcodePatternNumbers3SpaceNumbers2),
        example: '123 45',
      }
    case 'US':
      return {
        pattern: new RegExp(
          `(${postalCodePatternUs1})|(${postalCodePatternNumbers5})`,
        ),
        example: '12345; 12345-6789',
      }
    default:
      return { pattern: new RegExp(postalCodePatternDefault) }
  }
}
