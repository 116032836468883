import { TypedDocumentNode, gql, useApolloClient } from '@apollo/client'
import { useCallback } from 'react'

import {
  VerifyShippingDestinationQuery,
  VerifyShippingDestinationQueryVariables,
} from './useVerifyShippingDestination.generated'

const verifyShippingDestinationQuery = gql`
  query verifyShippingDestination($postalCode: String, $countryId: String) {
    verifyShippingDestination(
      input: { postal_code: $postalCode, country_id: $countryId }
    )
  }
` as TypedDocumentNode<
  VerifyShippingDestinationQuery,
  VerifyShippingDestinationQueryVariables
>

/**
 * Hook to verify if a shipping destination is valid
 */
const useVerifyShippingDestination = () => {
  const client = useApolloClient()

  return useCallback(
    /**
     * @param postalCode Shipping destination postal code
     * @param countryId Shipping destination country ID
     * @returns boolean
     */
    async (postalCode: string, countryId: string) => {
      const { data, ...others } = await client.query({
        query: verifyShippingDestinationQuery,
        variables: {
          postalCode,
          countryId,
        },
      })

      return {
        data: data.verifyShippingDestination,
        ...others,
      }
    },
    [client],
  )
}

export default useVerifyShippingDestination
