/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCountries } from '@emico-hooks/customer'
import { stripMaybes } from '@emico-utils/graphql-data-utils'
import styled from '@emotion/styled'
import { t, Trans } from '@lingui/macro'
import { countries as countriesList } from 'countries-list'
import React, { useEffect, useState } from 'react'
import {
  Control,
  FieldValues,
  Path,
  PathValue,
  UseFormRegister,
  UseFormSetFocus,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form'

import { minWidth } from '@emico/styles'

import { getPostalCodePattern } from '../lib/getPostalCodePattern'
import { useStoreConfig } from '../lib/storeConfig'
import useVerifyShippingDestination from '../lib/useVerifyShippingDestination'
import theme from '../theme'
import FormFieldsAddressAutocomplete, {
  AutocompleteAddressDetails,
} from './FormFieldsAddressAutocomplete'
import Input from './Input'
import Select from './Select'

type Scope = 'default' | 'shipping' | 'billing'

function getFieldNames(scope: Scope) {
  switch (scope) {
    case 'shipping':
      return {
        autocompleteAddress: 'shippingAutocompleteAddress',
        postalCode: 'shippingPostalCode',
        houseNumber: 'shippingHouseNumber',
        street: 'shippingStreet',
        city: 'shippingCity',
        country: 'shippingCountry',
        region: 'shippingRegion',
      }
    case 'billing':
      return {
        autocompleteAddress: 'billingAutocompleteAddress',
        postalCode: 'billingPostalCode',
        houseNumber: 'billingHouseNumber',
        street: 'billingStreet',
        city: 'billingCity',
        country: 'billingCountry',
        region: 'billingRegion',
      }
    default:
      return {
        autocompleteAddress: 'autocompleteAddress',
        postalCode: 'postalCode',
        houseNumber: 'houseNumber',
        street: 'street',
        city: 'city',
        country: 'country',
        region: 'region',
      }
  }
}

/**
 * Function to communicate if autocomplete address is available for current country code
 *
 * @param countryCode 2-letter country code of the country
 * @returns Boolean value
 */
function getAutocompleteAddressState(countryCode: string) {
  switch (countryCode) {
    case 'NL':
    case 'AT':
    case 'BE':
    case 'DK':
    case 'FR':
    case 'DE':
    case 'GB':
    case 'NO':
    case 'ES':
    case 'CH':
      return true
    default:
      return false
  }
}

const FormGrid = styled.div`
  display: grid;
  gap: ${theme.spacing.lg};

  @media ${minWidth('md')} {
    grid-template-columns: repeat(2, 1fr);
  }
`

const AutocompleteAddressWrapper = styled.div`
  grid-column: 1 / 3;
`

const FormFieldWrapper = styled(FormGrid, {
  shouldForwardProp: (prop) => prop !== 'hasTopOrder',
})<{ hasTopOrder?: boolean }>`
  grid-column: 1 / 3;
  order: ${({ hasTopOrder }) => hasTopOrder && -1};
`

const StyledSelect = styled(Select)`
  select {
    border-color: ${theme.colors.border};
  }
` as typeof Select

interface Props<T extends FieldValues> {
  control: Control<T>
  register: UseFormRegister<T>
  setValue: UseFormSetValue<T>
  setFocus: UseFormSetFocus<T>
  scope?: Scope
  hasChangeAddressState?: boolean
  defaultValues?: FieldValues
  isCountryDisabled?: boolean
  showAllCountries?: boolean
}

const FormFieldsAddress = <T extends FieldValues>({
  control,
  register,
  setValue,
  setFocus,
  scope = 'default',
  defaultValues = {},
  hasChangeAddressState,
  isCountryDisabled = true,
  showAllCountries = false,
}: Props<T>) => {
  const { storeConfig } = useStoreConfig()
  const { data: countriesData } = useCountries()
  const verifyShippingDestination = useVerifyShippingDestination()

  const [autocompleteAddressDetails, setAutocompleteAddressDetails] = useState<
    AutocompleteAddressDetails | undefined
  >(undefined)

  const fieldNames = getFieldNames(scope)

  const defaultCountryValue = storeConfig?.defaultCountryId ?? undefined

  const [selectedCountry, setSelectedCountry] = useState<string | undefined>(
    defaultCountryValue,
  )

  const countryValue = useWatch({
    control,
    name: fieldNames.country as Path<T>,
  })

  const postalCodePattern = getPostalCodePattern(countryValue).pattern
  const postalCodePatternExample = getPostalCodePattern(countryValue).example
  const isUK = countryValue?.toLowerCase() === 'gb'
  const isAutocompleteAddressCompatible = countryValue
    ? getAutocompleteAddressState(countryValue)
    : false

  const availableRegions = countriesData?.find(
    (country) => country?.twoLetterAbbreviation === countryValue,
  )?.availableRegions

  const hasRegions = Boolean(availableRegions?.length)

  const countries = countriesData
    ?.filter(stripMaybes)
    ?.filter((country) => country?.fullNameLocale)

  const allCountries = Object.entries(countriesList).map((country) => ({
    twoLetterAbbreviation: country[0],
    countryInfo: country[1],
  }))

  const handleAutocompleteDetailsChange = (
    details: AutocompleteAddressDetails | undefined,
  ) => setAutocompleteAddressDetails(details)

  /**
   * Function to check if a shipping destination is verified
   * @param postalCode Destination's postal code
   * @returns boolean
   */
  async function handleVerifyShippingDestination(postalCode: string) {
    const { data: isShippingDestinationVerified } =
      await verifyShippingDestination(postalCode, countryValue)

    return isShippingDestinationVerified ?? true
  }

  const errorMessages = {
    required: {
      streetName: t({
        message: 'Enter your street',
      }),
      houseNumber: t({
        message: 'Enter your house number',
      }),
      houseNumberIsUk: t({
        message: 'Enter your house number or name',
      }),
      postalCode: t({
        message: 'Enter your postal code',
      }),
      city: t({
        message: 'Enter your city',
      }),
      country: t({
        message: 'Select your country',
      }),
      region: t({
        message: 'Select your region',
      }),
    },
    pattern: {
      postalCode: postalCodePatternExample
        ? t({
            message: `Enter a postal code in the correct format. For example: ${postalCodePatternExample}`,
          })
        : t({
            message: 'Enter a postal code in the correct format.',
          }),
      verified: t({
        message:
          'Please note: unfortunately delivery to this region is not possible',
      }),
    },
  }

  useEffect(() => {
    if (defaultCountryValue) {
      setSelectedCountry(defaultCountryValue)

      setValue(
        fieldNames.country as Path<T>,
        defaultCountryValue as PathValue<T, Path<T>>,
      )
    }
  }, [defaultCountryValue, fieldNames.country, setValue])

  /**
   * Set values to address form fields based on autocompleteAddressDetails data
   */
  useEffect(() => {
    if (autocompleteAddressDetails) {
      setValue(
        fieldNames.street as Path<T>,
        autocompleteAddressDetails.address.street as PathValue<T, Path<T>>,
      )

      setValue(
        fieldNames.houseNumber as Path<T>,
        `${autocompleteAddressDetails.address.buildingNumber}${
          autocompleteAddressDetails.address.buildingNumberAddition
            ? ' ' + autocompleteAddressDetails.address.buildingNumberAddition
            : ''
        }` as PathValue<T, Path<T>>,
      )
      setValue(
        fieldNames.postalCode as Path<T>,
        autocompleteAddressDetails.address.postcode as PathValue<T, Path<T>>,
      )
      setValue(
        fieldNames.city as Path<T>,
        autocompleteAddressDetails.address.locality as PathValue<T, Path<T>>,
      )
    }
  }, [
    autocompleteAddressDetails,
    fieldNames.city,
    fieldNames.houseNumber,
    fieldNames.postalCode,
    fieldNames.street,
    setValue,
  ])

  return (
    <FormGrid>
      {isAutocompleteAddressCompatible && (
        <AutocompleteAddressWrapper>
          <FormFieldsAddressAutocomplete
            register={register}
            control={control}
            setValue={setValue}
            setFocus={setFocus}
            countryValue={countryValue}
            registerName={fieldNames.autocompleteAddress}
            hasAutoFocus={hasChangeAddressState}
            handleStateChange={handleAutocompleteDetailsChange}
          />
        </AutocompleteAddressWrapper>
      )}

      <Input
        control={control}
        label={t({
          message: 'Postal code',
        })}
        {...register(fieldNames.postalCode as Path<T>, {
          // Show input error when input has no value on submit
          required: {
            value: true,
            message: errorMessages.required.postalCode,
          },
          pattern: {
            value: postalCodePattern,
            message: errorMessages.pattern.postalCode,
          },
          validate: async (value) =>
            (await handleVerifyShippingDestination(value)) ||
            errorMessages.pattern.verified,
        })}
        autoFocus={hasChangeAddressState}
      />

      <Input
        control={control}
        label={
          isUK
            ? t({
                message: 'House number or name',
              })
            : t({
                message: 'House number + addition',
              })
        }
        {...register(fieldNames.houseNumber as Path<T>, {
          // Show input error when input has no value on submit
          required: {
            value: true,
            message: isUK
              ? errorMessages.required.houseNumberIsUk
              : errorMessages.required.houseNumber,
          },
        })}
      />

      <Input
        control={control}
        label={t({
          message: 'Street',
        })}
        {...register(fieldNames.street as Path<T>, {
          // Show input error when input has no value on submit
          required: {
            value: true,
            message: errorMessages.required.streetName,
          },
        })}
      />

      <Input
        control={control}
        label={t({
          message: 'City',
        })}
        {...register(fieldNames.city as Path<T>, {
          // Show input error when input has no value on submit
          required: {
            value: true,
            message: errorMessages.required.city,
          },
        })}
      />

      <FormFieldWrapper hasTopOrder={!isCountryDisabled}>
        <StyledSelect
          disabled={isCountryDisabled}
          control={control}
          value={selectedCountry}
          {...register(fieldNames.country as Path<T>, {
            // Show input error when input has no value on submit
            required: {
              value: true,
              message: errorMessages.required.country,
            },
            validate: (value) =>
              Boolean(value) || errorMessages.required.country,
          })}
          onChange={(ev) => {
            const countryValue = ev.target.value

            setSelectedCountry(countryValue)

            setValue(
              fieldNames.country as Path<T>,
              countryValue as PathValue<T, Path<T>>,
            )
            setValue(fieldNames.region as Path<T>, '' as PathValue<T, Path<T>>)
          }}
        >
          <>
            <option value="">
              <Trans>Country</Trans>
            </option>

            {showAllCountries
              ? allCountries?.map((country) => (
                  <option
                    key={country.twoLetterAbbreviation}
                    value={country.twoLetterAbbreviation}
                  >
                    {country.countryInfo.native}
                  </option>
                ))
              : countries?.map((country, index) => {
                  if (
                    !country?.twoLetterAbbreviation ||
                    !country.fullNameLocale
                  ) {
                    return null
                  }

                  return (
                    <option key={index} value={country.twoLetterAbbreviation}>
                      {country?.fullNameLocale}
                    </option>
                  )
                })}
          </>
        </StyledSelect>

        {hasRegions && (
          <StyledSelect
            control={control}
            defaultValue={defaultValues?.[`${fieldNames.region}`]}
            {...register(fieldNames.region as Path<T>, {
              // Show input error when input has no value on submit
              required: {
                value: hasRegions,
                message: errorMessages.required.region,
              },
            })}
          >
            <>
              <option value="">
                <Trans>Region</Trans>
              </option>

              {availableRegions?.map((region, index) => {
                if (!region?.id || !region.name) {
                  return null
                }

                return (
                  <option key={index} value={`${region.id}`}>
                    {region?.name}
                  </option>
                )
              })}
            </>
          </StyledSelect>
        )}
      </FormFieldWrapper>
    </FormGrid>
  )
}

export default FormFieldsAddress
