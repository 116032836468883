import styled from '@emotion/styled'
import { t } from '@lingui/macro'
import React from 'react'
import { Control, FieldValues, Path, UseFormRegister } from 'react-hook-form'

import { minWidth } from '@emico/styles'

import theme from '../theme'
import Input from './Input'

type Scope = 'default' | 'shipping' | 'billing'

function getFieldNames(scope: Scope) {
  switch (scope) {
    case 'shipping':
      return {
        firstname: 'shippingFirstname',
        lastname: 'shippingLastname',
      }
    case 'billing':
      return {
        firstname: 'billingFirstname',
        lastname: 'billingLastname',
      }

    default:
      return {
        firstname: 'firstname',
        lastname: 'lastname',
      }
  }
}

const FormGrid = styled.div`
  display: grid;
  gap: ${theme.spacing.lg};

  @media ${minWidth('md')} {
    grid-template-columns: repeat(2, 1fr);
  }
`

interface Props<T extends FieldValues> {
  register: UseFormRegister<T>
  control: Control<T>
  scope?: Scope
  autoFocus?: boolean
}

const FormFieldsName = <T extends FieldValues>({
  register,
  control,
  scope = 'default',
  autoFocus = false,
}: Props<T>) => {
  const fieldNames = getFieldNames(scope)

  const errorMessages = {
    required: {
      firstName: t({
        message: 'Enter your first name',
      }),
      lastName: t({
        message: 'Enter your last name',
      }),
    },
  }

  return (
    <FormGrid>
      <Input
        control={control}
        label={t({
          message: 'First name',
        })}
        {...register(fieldNames.firstname as Path<T>, {
          // Show input error when input has no value on submit
          required: {
            value: true,
            message: errorMessages.required.firstName,
          },
        })}
        autoFocus={autoFocus}
        maxLength={15}
      />

      <Input
        control={control}
        label={t({
          message: 'Last name',
        })}
        {...register(fieldNames.lastname as Path<T>, {
          // Show input error when input has no value on submit
          required: {
            value: true,
            message: errorMessages.required.lastName,
          },
        })}
        maxLength={20}
      />
    </FormGrid>
  )
}

export default FormFieldsName
